﻿/*
  app.js
  Global Script for project included on every page.
*/

// Side navigation
const sidenav = require('../../ga/scripts/partials/sidenav');

(function () {
  if (document.getElementById('sideNavBurger')) {
    document.getElementById('sideNavBurger').addEventListener('click', sidenav.open);
  }
  if(document.getElementById('sideNavBurger')) {
    document.getElementById('globalSidenavClose').addEventListener('click', sidenav.close);
    document.addEventListener('click', e => {
      if(document.getElementById('globalSidenav') && !document.getElementById('globalSidenav').contains(e.target) && !document.getElementById('sideNavBurger').contains(e.target)) {
        sidenav.close();
      }
    });
  }
})();